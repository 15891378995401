/* Slider container */
.slider-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Nome do slider alinhado à esquerda */
    margin-bottom: 10px;
}

/* Label dos sliders */
.slider-container .label {
    flex: 1;
    font-size: 1rem;
    color: white;
    text-align: left; /* Texto alinhado à esquerda */
}

/* Estilos dos sliders com cores individuais */
.slider {
    flex: 2;
    margin-left: 10px;
    height: 10px;
    -webkit-appearance: none;
    border-radius: 5px;
}

.slider[name="custoFixo"] {
    background-color: #E6E6FA;
}

.slider[name="conforto"] {
    background-color: #EE82EE;
}

.slider[name="metas"] {
    background-color: #9370DB;
}

.slider[name="prazeres"] {
    background-color: #00BFFF;
}

.slider[name="liberdadeFinanceira"] {
    background-color: #00FA9A;
}

.slider[name="conhecimento"] {
    background-color: #FF6347;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
}

/* Estilos para o título "Ajuste Metas" */
.title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
    margin-bottom: 20px;
}

/* Estilo para o texto do total */
.total {
    text-align: center;
    color: white;
    margin-top: 20px;
    font-size: 1.2rem;
}

/* Estilo para o botão de salvar */
.button-container {
    text-align: center;
    margin-top: 10px;
}

.button-save {
    padding: 10px 20px;
    background-color: #00FA9A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.button-save:hover {
    background-color: #00d982;
}

/* Tornar os dois containers de gráfico e sliders do mesmo tamanho */
.container-box {
    height: 100%; /* Garante que os dois blocos tenham o mesmo tamanho */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
