/* Estilos gerais */
body {
    background-color: #1e1e1e;
    color: white;
    font-family: Arial, sans-serif;
    margin: 0;
    width: '100%';
    padding: 0;
}

/* Contêiner principal */
.main-container {
    margin-left: 200px;
    padding: 20px;
    box-sizing: border-box;
}

/* Barra lateral */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100vh;
    background-color: #1e1e1e;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 60px 0;
    text-align: center;
}

.sidebar li {
    margin-bottom: 60px;
}

.sidebar a {
    color: white;
    text-decoration: none;
}

.sidebar a:hover {
    text-decoration: underline;
}

/* Conteúdo principal */
.content-container {
    background-color: #2c2c2c;
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
}

.header-container {
    text-align: center;
    margin-bottom: 20px;
}

/* Estilos das seções */
.section {
    display: flex;
    justify-content: space-between;
}

.section1, .section2, .section3 {
    background-color: #333;
    padding: 15px;
    border-radius: 10px;
}

.section2 {
    width: 60%;
}

.section3 {
    width: 30%;
    text-align: center;
}

/* Estilos da tabela */
.table-container {
    overflow-x: auto;
}

.financial-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1e1e1e;
}

.financial-table th, .financial-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px #1e1e1e;
    color: rgb(214, 21, 21);
}

.financial-table th {
    background-color: #222;
}

.financial-table tr:hover {
    background-color: #333;
}

.highlight {
    color: #FF0000;
}

/* Estilos de cabeçalho */
.finance-header, .descriptionMeta {
    font-size: 24px;
    font-weight: bold;
    color: yellow;
}

/* Responsividade */
@media (max-width: 768px) {
    .main-container, .sidebar {
        width: 100%;
        margin: 0;
        padding: 10px;
    }

    .section1, .section2, .section3 {
        width: 100%;
        margin: 5px 0;
    }
    
    .content-container {
        margin-left: 0;
        padding: 10px;
    }

    .section {
        flex-direction: column;
    }

    .section1, .section2, .section3 {
        width: 100%;
        margin-bottom: 20px;
    }
}
