body {
    background-image: url('/src/static/fundo.png'); /* Caminho para a imagem de fundo */
    background-size: cover; /* Faz com que a imagem cubra toda a área */
    background-position: center; /* Centraliza a imagem */
    background-repeat: no-repeat; /* Não repete a imagem */
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
}

.login-box {
    background-color: rgba(255, 255, 255, 0.9); /* Fundo branco semi-transparente para a caixa de login */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgb(245, 245, 245);
    width: 100%; /* Altere aqui para 100% da largura do contêiner */
    max-width: 400px; /* Limite o tamanho máximo da caixa de login */
}

.login-title {
    font-size: 24px; /* Tamanho da fonte do título */
    color: #343a40;
    margin-bottom: 30px;
    text-align: center; /* Centralizar título */
}

.login-input {
    margin-bottom: 20px;
}

.login-error {
    margin-bottom: 15px;
    font-size: 14px;
}

.login-button {
    font-size: 18px;
    padding: 12px;
    margin-top: 20px;
    text-transform: none;
    background-color: #007bff;
    color: white;
}

.login-button:hover {
    background-color: #0056b3;
}

.login-forgot-password {
    margin-top: 15px;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    text-align: center; /* Centralizar texto */
}

.login-register {
    margin-top: 20px;
    font-size: 14px;
    text-align: center; /* Centralizar texto */
}

.login-register a {
    color: #007bff;
    text-decoration: none;
}

.login-register a:hover {
    color: #0056b3;
    text-decoration: underline;
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    text-decoration: none;
}

.back-button:hover {
    background-color: #0056b3;
}
