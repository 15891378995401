/* CSS Global */
body {
  background-color: #1c1c1e; /* Fundo preto para contraste */
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.Container {
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

h1 {
  color: #333333; /* Cor do título */
}

/* Estilo das Boxes */
.MuiBox-root {
  background-color: #f9f9f9; /* Cor clara */
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.MuiTypography-root {
  color: #333333;
}

/* Estilo dos TextFields */
.MuiTextField-root {
  background-color: #ffffff;
  border-radius: 4px;
}

/* Borda e cor dos TextFields */
.MuiOutlinedInput-root {
  fieldset {
    border-color: #cccccc;
  }
}

.MuiInputLabel-root {
  color: #888888;
}

.MuiTextField-root.Mui-focused .MuiOutlinedInput-root fieldset {
  border-color: #6d6dff;
}

.MuiFormHelperText-root {
  color: #ff5252;
}

/* Estilo dos Botões */
.MuiButton-contained {
  background-color: #6d6dff;
  color: #ffffff;
}

.MuiButton-outlined {
  border-color: #6d6dff;
  color: #6d6dff;
}

.MuiButton-contained:hover,
.MuiButton-outlined:hover {
  background-color: #5a5acc;
}
